import type { AxiosRequestConfig } from "axios";
import type { Result } from "../../../../types";
import type { PathBuilder } from "../../../../utils";
import { client } from "../../axios";
import { emailRegistration } from "./emailRegistration";
import { images } from "./images";
import { languages } from "./languages";
import { publish } from "./publish";
import { styles } from "./styles";
import { texts } from "./texts";
import { userActions } from "./userActions";
import { vehicleEvents } from "./vehicleEvents";
import { versions } from "./versions";

export type HeaderTemplates =
  | "with_left_aligned_logo"
  | "with_centered_logo"
  | "no_header";

export type BodyTemplates =
  | "email_registration_two_column"
  | "connect_with_checkbox"
  | "connect_without_checkbox"
  | "connect_with_checkbox_and_usage"
  | "connect_with_image_above"
  | "connect_with_image_below"
  | "connect_with_email_registration"
  | "connect_with_multiple_sections"
  | "connect_with_checkbox_logo_leftAligned"
  | "db_fv";

export type FooterTemplates =
  | "with_terms"
  | "with_terms_privacy"
  | "with_contact_terms"
  | "with_contact_terms_privacy"
  | "with_contact_terms_privacy_usage"
  | "with_contact_terms_privacy_faq_customSelect";

export type GeneralTemplates = "general";

export type TemplatesKeys = {
  header: HeaderTemplates;
  body: BodyTemplates;
  footer: FooterTemplates;
  general: GeneralTemplates;
};

export type Template = "header" | "body" | "footer" | "general";

export type LocalAttributes = {
  icomera_realm: string;
  ipv4_subnet_star_masks: string[];
  health_check_url: string;
  is_default: boolean;
  portal_key: string;
  upserted_at: string;
};

export type CloudAttributes = Partial<{
  default_realm: string;
  complimentary_realm: string;
  venue_url: string;
  email_verification_subject: string;
  email_verification_from_email: string;
}>;

export type PortalEnvironment = "cloud" | "local";

export type Project = {
  id: number;
  name: string;
  templates: TemplatesKeys;
  domains: Record<string, string>;
  features: Record<string, any>;
  icomera_customer_id: number;
  icomera_account_id: number;
  local_attributes?: LocalAttributes;
  portal_class: string;
  portal_env: PortalEnvironment;
  api_type: string;
  default_version?: number;
  cloud_attributes?: CloudAttributes;
};

export type GetProjectResult = Result<Project>;

export type PostDomainBody = {
  prefix: string;
};

export type PostDomainResult = Result<{
  rows_affected: number;
}>;

export type DeleteProjectResult = Result<{
  count: number;
}>;

export type PutProjectBody = {
  name?: string;
  icomera_customer_id?: number;
  icomera_account_id?: number;
  online_domain_prefix?: string;
  api_type?: string;
  portal_env?: "cloud" | "local";
  default_version?: number;
  cloud_attributes?: CloudAttributes;
};

export type PutProjectResult = Result<{
  rows_affected: number;
}>;

export type PostProjectFeaturesBody = Record<string, any>;
export type PostProjectFeaturesResult = Result<{
  updated_ids: number[];
}>;

export type PostApplyTemplateBody = Record<string, string>;
export type PostApplyTemplateResult = Result<
  Record<
    string,
    {
      link_id: number;
      drop_result: {
        style_values_count: number;
        text_values_count: number;
        image_values_count: number;
      };
      insert_result: {
        style_values_count: number;
        text_values_count: any;
        image_values_count: number;
      };
    }
  >
>;

export const project = (path: PathBuilder) => {
  const get = (config?: AxiosRequestConfig) =>
    client.get<GetProjectResult>(path.value, config);

  const put = (body: PutProjectBody, config?: AxiosRequestConfig) =>
    client.put<PutProjectResult>(path.value, body, config);

  const del = (config?: AxiosRequestConfig) =>
    client.delete<DeleteProjectResult>(path.value, config);

  const postDomain = (body: PostDomainBody, config?: AxiosRequestConfig) =>
    client.post<PostDomainResult>(path.slash("domains").value, body, config);

  const postFeatures = (
    body: PostProjectFeaturesBody,
    config?: AxiosRequestConfig
  ) =>
    client.post<PostProjectFeaturesResult>(
      path.slash("features").value,
      body,
      config
    );

  const postApplyTemplate = (
    body: PostApplyTemplateBody,
    config?: AxiosRequestConfig
  ) =>
    client.post<PostApplyTemplateResult>(
      path.slash("templates").slash("apply").value,
      body,
      config
    );

  return {
    get,
    put,
    delete: del,
    postDomain,
    postFeatures,
    postApplyTemplate,
    vehicleEvents: vehicleEvents(path.slash("vehicles_events")),
    images: images(path.slash("images")),
    emailRegistration: emailRegistration(path.slash("email-registration")),
    styles: styles(path.slash("styles")),
    texts: texts(path.slash("texts")),
    publish: publish(path.slash("publish")),
    versions: versions(path.slash("versions")),
    userActions: userActions(path.slash("user_actions")),
    languages: languages(path.slash("languages")),
  };
};

export * from "./emailRegistration";
export * from "./images";
export * from "./languages";
export * from "./publish";
export * from "./styles";
export * from "./texts";
export * from "./userActions";
export * from "./vehicleEvents";
export * from "./versions";
