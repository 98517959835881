<template>
  <div v-if="!isDeletingRow" class="settings-section__row draggable">
    <span>{{ data.display_name }}</span>
    <div class="flex-row-center">
      <EditorCheckbox
        v-if="isShowMandatoryCheckbox"
        class="settings-section__is-mandatory"
        title="Mandatory field"
        :value="data.required"
        @update:option="onChangeIsMandatory"
      />
      <span class="icon-delete" @click="isDeletingRow = true" />
      <span class="icon-reorder" />
    </div>
  </div>

  <div v-else class="settings-section__row">
    <SettingsConfirmationRow
      title="You’re about to delete a field. Are you sure?"
      :buttons-params="buttonsParams"
    />
  </div>
</template>

<script lang="ts">
import { type ButtonProps } from "@/components/button/types";
import EditorCheckbox from "@/components/checkbox/EditorCheckbox.vue";
import SettingsConfirmationRow from "@/routes/settings/sectionElements/SettingsConfirmationRow.vue";
import emailRegistrationService from "@/services/emailRegistration.service";
import type {
  EmailRegistrationField,
  PostEmailRegistrationFieldInput,
} from "@cna/api/editor";
import { computed, defineComponent, ref, type PropType } from "vue";

export default defineComponent({
  name: "FormsSectionRow",
  components: {
    EditorCheckbox,
    SettingsConfirmationRow,
  },
  props: {
    data: {
      type: Object as PropType<EmailRegistrationField>,
      required: true,
    },
  },
  setup(props) {
    const isDeletingRow = ref(false);
    const isShowMandatoryCheckbox = computed(
      () => props.data.kind !== "other_text"
    );
    const onChangeIsMandatory = (value: boolean) =>
      emailRegistrationService.updateField(props.data.key, {
        ...props.data,
        required: value,
      } as PostEmailRegistrationFieldInput);
    const enableFormElement = () => {
      emailRegistrationService.toggleField(props.data.key, false);
    };

    const buttonsParams: ButtonProps[] = [
      {
        title: "Cancel",
        onClick: () => (isDeletingRow.value = false),
      },
      {
        title: "Delete",
        onClick: () => {
          enableFormElement();
          isDeletingRow.value = false;
        },
      },
    ];

    return {
      onChangeIsMandatory,
      isDeletingRow,
      buttonsParams,
      isShowMandatoryCheckbox,
    };
  },
});
</script>

<style lang="scss" scoped>
.flex-row-center span {
  margin: 0 10px;
}

.settings-section__row {
  position: relative;
}

.icon-delete {
  font-size: 20px;
}

.icon-reorder {
  font-size: 24px;
  color: $grey14;
}

.settings-section__is-mandatory {
  margin-right: 29px;
}
</style>
