import { api } from "@/services/api";
import { AxiosError } from "axios";
import type { RouteRecordRaw } from "vue-router";

import { views } from "./views";

const beforeEnter: RouteRecordRaw["beforeEnter"] = async (_, from, next) => {
  const auth = await api.v1.me
    .getIsSuperUser()
    .then((res) => res.status)
    .catch((e) => {
      if (e instanceof AxiosError) {
        return e.response?.status;
      }

      return -1;
    });

  if (auth === 200) {
    return next();
  }

  return next(from);
};

export const adminRoute: RouteRecordRaw = {
  path: "admin",
  component: () => import("@/admin/layout/index.vue"),
  meta: {
    key: "admin",
  },
  children: [
    {
      path: "",
      redirect(_) {
        return {
          name: "admin-attributes",
        };
      },
    },
    ...views.map((v) => ({ ...v, beforeEnter })),
  ],
};
