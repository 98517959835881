import type { AxiosRequestConfig } from "axios";
import type { Result } from "../../../types";
import type { PathBuilder } from "../../../utils";
import { client } from "../axios";
import { project, type LocalAttributes } from "./project";

export type ListProjectData = {
  id: number;
  name: string;
  online_domains_prefix: string;
  portal_class: string;
  portal_env: "local" | "cloud";
  api_type: string;
  icomera_customer_id: number;
  icomera_account_id: number;
  local_attributes?: LocalAttributes[];
};

export type GetProjectsResult = Result<ListProjectData[]>;

export type PostLocalAttributesBody = {
  portals: ({
    project_id: number;
  } & Omit<LocalAttributes, "upserted_at">)[];
};

export type PostLocalAttributesResult = Result<Record<number, number>>;

export type PostProjectBody = {
  id: number;
  name: string;
  icomera_customer_id: number;
  icomera_account_id: number;
  portal_class: string;
  portal_env: "cloud" | "local";
  api_type: string;
  online_domain_prefix: string;
  local_projects_attribs: LocalAttributes;
  styles: {
    template_type: string;
    raw_styles: Record<string, any>;
  };
  text_values: {
    language_tag: string;
    template_name: string;
    field_name: string;
    value: string;
  }[];
};

export type GetDomainsResult = Result<string[]>;

export type PostCopyProjectResult = Result<{ count: number }>;

export const projects = (path: PathBuilder) => {
  const get = (config?: AxiosRequestConfig) =>
    client.get<GetProjectsResult>(path.value, config);

  const post = (body: PostProjectBody, config?: AxiosRequestConfig) =>
    client.post<Result<any>>(path.value, body, config);

  const postLocalAttributes = (
    body: PostLocalAttributesBody,
    config?: AxiosRequestConfig
  ) =>
    client.post<PostLocalAttributesResult>(
      path.slash("local_attributes").value,
      body,
      config
    );

  const postCopy = (from: number, to: number, config?: AxiosRequestConfig) =>
    client.post<PostCopyProjectResult>(
      path.slash(from).slash("copy").slash("to").slash(to).value,
      undefined,
      config
    );

  const getDomains = (config?: AxiosRequestConfig) =>
    client.get<GetDomainsResult>(path.slash("domains").value, config);

  return {
    get,
    post,
    postLocalAttributes,
    getDomains,
    postCopy,
    project: (projectId: string | number) => project(path.slash(projectId)),
  };
};

export * from "./project";
