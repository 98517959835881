import {
  type EditIconConfig,
  type EditIconModifiers,
  type RouteEditIconConfig,
} from "@/mixins/editIcon/types";
import { openModal } from "@/modals";
import router from "@/routes/router";
import { useProjectsStore } from "@/stores/projects";
import { computed } from "vue";

export const editIconMixin = (config: RouteEditIconConfig): any => ({
  mounted() {
    setTimeout(() => {
      const currentRoute = router.currentRoute.value.meta?.key;
      if (currentRoute === "text-editor" || currentRoute === "theme-editor") {
        applyEditIconToDomElements(config.get(`${currentRoute}`)!);
      }
    }, 750);
  },
});

const applyEditIconToDomElements = ({
  rootSelector,
  properties,
  pageName,
}: EditIconConfig) => {
  const projects = useProjectsStore();
  const currentScreenSize = computed(() => {
    return projects.currentScreenSize;
  });
  for (const prop of properties) {
    const element = document.querySelector(
      `${rootSelector} ${prop.selector}`
    ) as HTMLElement;
    if (!element) continue;
    element.style.position = "relative";
    const icon = document.createElement("button");
    icon.id = prop.fieldName; // useful for tests
    icon.className = `edit-icon icon-edit-${String(prop.iconType)} edit-icon--${
      currentScreenSize.value
    } `;
    icon.setAttribute("aria-label", "Edit icon");
    icon.onclick = (event: Event) => {
      // Prevent form events when edit icon is inside of the button
      event.preventDefault();

      const baseProps = {
        templateName: prop.templateName,
        title: prop.modalTitle,
        pageName,
        closable: true,
      };

      const props =
        prop.modalType === "ButtonStylesEditingModal"
          ? baseProps
          : {
              fieldName: prop.fieldName,
              ...baseProps,
            };

      openModal(prop.modalType, props);
    };
    calculatePosition(icon, prop.modifiers!);
    const existing = element.querySelector(`#${prop.fieldName}`);
    if (existing) {
      existing.remove();
    }
    element.append(icon);
  }
};

const calculatePosition = (
  icon: HTMLButtonElement,
  modifiers: EditIconModifiers
) => {
  const outerIconOffset = modifiers?.outer ? "-40px" : "0";
  icon.style.right = outerIconOffset;

  if (modifiers?.center) {
    icon.style.left = "50%";
    icon.style.transform = "translate(-50%, 50%)";
  }

  if (modifiers?.left) {
    icon.style.left = outerIconOffset;
  }

  if (modifiers?.top) {
    icon.style.top = "0";
  }

  if (modifiers?.offsetX) {
    icon.style.transform = `translate(${modifiers?.offsetX}px, 50%)`;
  }

  if (modifiers?.offsetY) {
    icon.style.transform = `translateY(calc(50% + ${modifiers?.offsetY}px))`;
  }
};
