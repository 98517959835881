import type { AxiosRequestConfig } from "axios";
import type { Result } from "../../types";
import { type PathBuilder } from "../../utils";
import { client } from "./axios";

type UserMetadata = Result<{
  email: string;
  role_external: string;
  role_internal: string;
}>;

export const me = (path: PathBuilder) => {
  const getAuthenticated = (config?: AxiosRequestConfig<undefined>) =>
    client.get<void>(path.slash("authenticated").value, config);

  const getIsSuperUser = (config?: AxiosRequestConfig<undefined>) =>
    client.get<void>(path.slash("is-super-user").value, config);

  const get = (config?: AxiosRequestConfig<undefined>) =>
    client.get<UserMetadata>(path.value, config);

  const postLogout = (config?: AxiosRequestConfig<undefined>) =>
    client.post<Result<{ url: string }>>(
      path.slash("logout").value,
      undefined,
      config
    );

  return {
    getIsSuperUser,
    getAuthenticated,
    get,
    postLogout,
  };
};
