<template>
  <div ref="editorHeader" class="editor-header flex-row-center-space-between">
    <DropdownWithSearch
      :items="projectsDropdownOptions"
      :selected-project="currentProject"
      @select="redirectToProject"
      @change="onChangeSearch"
      @close="onChangeSearch('')"
    />
    <HeaderNavigation />
    <div class="flex-row-center">
      <EditorButton
        :title="buttonsParams.title"
        :button-style="buttonsParams.buttonStyle"
        :on-click="buttonsParams.onClick"
      />
      <HeaderSubMenu />
    </div>
  </div>
  <ConfirmationModal
    v-if="showConfirmationModal && confirmationModalConfig"
    @close="showConfirmationModal = false"
    :config="confirmationModalConfig"
  />
</template>

<script lang="ts">
import EditorButton from "@/components/button/EditorButton.vue";
import { type ButtonProps } from "@/components/button/types";
import { ConfirmationModal, type ConfirmationModalProps } from "@/modals";
import HeaderSubMenu from "./components/HeaderSubMenu.vue";

import versionsHistoryService from "@/services/versionsHistory.service";
import { renderSuccessToast } from "@/utils/general";
import { useElementBounding } from "@vueuse/core";
import { computed, defineComponent, ref } from "vue";
import { ButtonStyle } from "../button/types";
import DropdownWithSearch from "../dropdown/DropdownWithSearch.vue";
import { usePagesDropdownHelper } from "../dropdown/usePagesDropdownHelper";
import HeaderNavigation from "./components/HeaderNavigation.vue";

const editorHeader = ref<HTMLDivElement | null>(null);

export const headerBounds = useElementBounding(editorHeader);
export const headerHeight = computed(() => headerBounds.height.value + "px");

export default defineComponent({
  name: "EditorHeader",
  components: {
    HeaderNavigation,
    DropdownWithSearch,
    ConfirmationModal,
    EditorButton,
    HeaderSubMenu,
  },
  setup() {
    const showConfirmationModal = ref(false);
    const confirmationModalConfig = ref<ConfirmationModalProps["config"]>();
    const buttonsParams: ButtonProps = {
      title: "Save version",
      buttonStyle: ButtonStyle.GREEN,
      onClick: () => {
        confirmationModalConfig.value = {
          confirmAction: async (value: string) => {
            await versionsHistoryService
              .createVersion(value)
              .then(renderSuccessToast);
            await versionsHistoryService.fetchVersions();
          },
          modalTitle: "Save version",
          modalText:
            "You’re about to create an updated version of the Portal. This new version will be saved to the Version history. Please add a description of the changes made to this version:",
          textArea: true,
          buttonsConfigs: {
            confirmButton: {
              buttonTitle: "Create",
            },
            cancelButton: {
              buttonTitle: "Cancel",
            },
          },
        };

        showConfirmationModal.value = true;
      },
    };

    const {
      redirectToProject,
      projectsDropdownOptions,
      onChangeSearch,
      currentProject,
    } = usePagesDropdownHelper();

    return {
      editorHeader,
      showConfirmationModal,
      confirmationModalConfig,
      buttonsParams,
      redirectToProject,
      projectsDropdownOptions,
      onChangeSearch,
      currentProject,
    };
  },
});
</script>

<style lang="scss" scoped>
.editor-header {
  padding: 12px 20px;
  background-color: $dark-blue;
  color: $white;
  font-family: $nunito;
  position: fixed;
  z-index: 21;
}

.editor-header:deep(.dropdown__select) {
  height: 32px;
  padding: 0 12px;
  font-family: $nunito;
  font-size: 14px;
  font-weight: bold;
}

.editor-header:deep(.button) {
  border-radius: 4px;
  line-height: 16px;
  font-weight: 800;
  font-size: 14px;
}

.editor-header:deep(.custom-dropdown__selected-item) {
  background-color: transparent;
  color: $white;
  border-color: #6888ab;
}
</style>
