<template>
  <section class="languages settings-section">
    <SettingsSectionHeader heading="Languages" icon="icon-languages" />
    <draggable
      v-model="selectedLanguages"
      item-key="order"
      draggable=".draggable"
      @change="changeOrder"
    >
      <template #item="{ element }">
        <LanguagesSectionRow
          :item="element"
          @delete="deleteLanguage"
          @setPrimary="setPrimaryLanguage"
        />
      </template>
    </draggable>
    <AddNewLanguageRow @update:option="addNewLanguage" />
  </section>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from "vue";
import { useLanguagesStore } from "@/stores/languages";
import LanguagesSectionRow from "@/routes/settings/languageSection/LanguagesSectionRow.vue";
import draggable from "vuedraggable";
import AddNewLanguageRow from "@/routes/settings/languageSection/AddNewLanguageRow.vue";
import useLanguagesOrderHandler from "@/routes/settings/languageSection/useLanguagesOrderHandler";
import useLanguagesSettingsHandlers from "@/routes/settings/languageSection/useLanguagesSettingsHandlers";
import SettingsSectionHeader from "@/routes/settings/sectionElements/SettingsSectionHeader.vue";

export default defineComponent({
  name: "LanguagesSection",
  components: {
    LanguagesSectionRow,
    draggable,
    AddNewLanguageRow,
    SettingsSectionHeader,
  },
  setup() {
    const languages = useLanguagesStore();
    const sortedLanguages = computed(() => languages.sortedLanguages);
    const selectedLanguages = ref(sortedLanguages.value);
    const { changeOrder } = useLanguagesOrderHandler(selectedLanguages);
    const { addNewLanguage, deleteLanguage, setPrimaryLanguage } =
      useLanguagesSettingsHandlers();

    const updateSelectedLanguages = () =>
      (selectedLanguages.value = sortedLanguages.value);

    watch(
      () => sortedLanguages.value,
      () => {
        updateSelectedLanguages();
      }
    );

    return {
      selectedLanguages,
      addNewLanguage,
      changeOrder,
      deleteLanguage,
      setPrimaryLanguage,
    };
  },
});
</script>
