import { pathBuilder } from "../../utils";
import { files } from "./files";
import { languageTags } from "./languageTags";
import { me } from "./me";
import { projects } from "./projects";
import { templates } from "./templates";

export const v1 = () => {
  const path = pathBuilder("");

  return {
    files: files(path.slash("files")),
    languageTags: languageTags(path.slash("language_tags")),
    projects: projects(path.slash("projects")),
    templates: templates(path.slash("templates")),
    me: me(path.slash("me")),
  };
};

export * from "./files";
export * from "./languageTags";
export * from "./me";
export * from "./projects";
export * from "./templates";
