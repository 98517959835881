<template>
  <!--Row if domain is not edited -->
  <div v-if="isShowNotEditRow" class="settings-section__row">
    <span class="flex-row-center">
      <span class="domains-section__full-url">{{ fullURL }}</span>
      <span v-tooltip="'Copy URL'" @click="copyUrl">
        <span class="icon-copy" />
      </span>
    </span>
    <EditorButton v-if="isEditableRow" title="Edit" :on-click="editRow" />
  </div>

  <!--Row if domain is creating or editing-->
  <div v-if="isEdited || isCreating" class="settings-section__row">
    <label>
      <span class="domains-section__protocol">{{ DOMAIN_PROTOCOL }}</span>
      <EditorInput :value="prefix" @update:option="onChangePrefix" />
      <span class="domains-section__suffix">{{ suffix }}</span>
    </label>
    <ButtonsGroup :buttons-params="buttonsParams" />
  </div>
  <SettingsEmptyRow v-if="isShowEmptyRow" title="No domain set yet" />
  <ConfirmationModal
    v-if="showConfirmationModal && confirmationModalConfig"
    @close="showConfirmationModal = false"
    :config="confirmationModalConfig"
  />
</template>

<script lang="ts">
import EditorButton from "@/components/button/EditorButton.vue";
import ButtonsGroup from "@/components/button/ButtonsGroup.vue";
import { type ButtonProps } from "@/components/button/types";
import EditorInput from "@/components/editorInput/EditorInput.vue";
import { DOMAIN_PROTOCOL } from "@/constants";
import { ConfirmationModal, type ConfirmationModalProps } from "@/modals";
import { $toastify, TOASTIFY_TEXTS } from "@/plugins/toastify";
import SettingsEmptyRow from "@/routes/settings/sectionElements/SettingsEmptyRow.vue";
import versionsHistoryService from "@/services/versionsHistory.service";
import { useProjectsStore } from "@/stores/projects";
import { useVersionsHistoryStore } from "@/stores/versionsHistory";
import type { Maybe } from "@cna/common";
import { computed, defineComponent, ref } from "vue";

export default defineComponent({
  name: "LanguagesSectionRow",
  components: {
    ButtonsGroup,
    EditorButton,
    EditorInput,
    SettingsEmptyRow,
    ConfirmationModal,
  },
  props: {
    domain: {
      type: String,
      required: false,
      default: "",
    },
    type: {
      type: String,
      required: false,
      default: "",
    },
    isCreating: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["update:isCreating"],
  setup(props, { emit }) {
    const isEdited = ref(false);
    const projects = useProjectsStore();
    const versionsHistory = useVersionsHistoryStore();
    const suffix = computed(
      () => `.${versionsHistory.publishedDomains.suffix}`
    );
    const prefix = ref(props.domain.replace(suffix.value, ""));
    const fullURL = computed(() => `https://${props.domain}`);
    const isEditableRow = computed(() => props.type !== "preview");
    const isShowNotEditRow = computed(() => props.domain && !isEdited.value);
    const isShowEmptyRow = computed(() => !props.domain && !props.isCreating);

    const closeEdit = () => {
      isEdited.value = false;
      emit("update:isCreating", false);
    };

    const confirmationModalConfig = ref<ConfirmationModalProps["config"]>();
    const showConfirmationModal = ref(false);

    const buttonsParams: ButtonProps[] = [
      {
        title: "Cancel",
        onClick: () => closeEdit(),
      },
      {
        title: "Save",
        onClick: () => {
          confirmationModalConfig.value = {
            confirmAction: async () => {
              prefix.value = prefix.value.toLowerCase();
              const newURL = prefix.value + suffix.value;
              await versionsHistoryService.createVersion(
                `changed domain to ${newURL}`
              );
              await versionsHistoryService.updateDomain(prefix.value);
              projects.currentProject.domains!.production = newURL;
              closeEdit();
            },
            modalTitle: "Update domain?",
            modalText:
              "<p>The domain will be updated after you publish the latest version.</p>",
            buttonsConfigs: {
              confirmButton: {
                buttonTitle: "Confirm",
              },
              cancelButton: {
                buttonTitle: "Cancel",
              },
            },
            textArea: false,
          };

          if (!prefix.value) {
            $toastify.show({
              messageText: "Domain prefix can't be empty",
              toastifyType: "danger",
            });
            return;
          }
          showConfirmationModal.value = true;
        },
      },
    ];

    const onChangePrefix = (value: Maybe<string>) => {
      prefix.value = value || "";
    };

    const copyUrl = () => {
      navigator.clipboard.writeText(fullURL.value);
      $toastify.show({
        messageText: TOASTIFY_TEXTS.copyUrl,
        toastifyType: "success",
      });
    };
    const editRow = () => (isEdited.value = true);

    return {
      buttonsParams,
      isShowEmptyRow,
      isShowNotEditRow,
      isEditableRow,
      onChangePrefix,
      copyUrl,
      editRow,
      isEdited,
      prefix,
      suffix,
      fullURL,
      DOMAIN_PROTOCOL,
      showConfirmationModal,
      confirmationModalConfig,
    };
  },
});
</script>

<style lang="scss" scoped>
.domains-section__full-url {
  margin-right: 10px;
}

.domains-section__protocol {
  padding-right: 10px;
  color: $grey13;
}

.domains-section__suffix {
  padding-left: 10px;
  color: $grey13;
}

.settings-section__row:deep(input) {
  min-width: 380px;
}

.icon-copy {
  font-size: 22px;
}
</style>
