<template>
  <OneColumnLayout id="secondary-page-content">
    <div class="page__main-block">
      <div class="page__text-block">
        <div class="page__text">
          <div :class="mainBlockClass">
            <Markdown :data="markdownData" />
            <div v-if="actionBlock" class="page__main-block-actions">
              <CommonButton :config="buttonConfig" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </OneColumnLayout>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { useCommonGeneralStore } from "../../../stores";
import CommonButton from "../../button/CommonButton.vue";
import { OneColumnLayout } from "../../columns";
import Markdown from "../../markdown/Markdown.vue";

export default defineComponent({
  name: "SecondaryPageBody",
  components: {
    Markdown,
    CommonButton,
    OneColumnLayout,
  },
  props: {
    markdownData: {
      type: String,
      required: true,
    },
    isScrolled: {
      type: Boolean,
      required: false,
      default: false,
    },
    actionBlock: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup(props) {
    const commonGeneral = useCommonGeneralStore();
    const mainBlockClass = computed(() => {
      return props.isScrolled
        ? "page__markdown page__markdown-with-scroll"
        : "page__markdown";
    });

    const buttonConfig = computed(() => ({
      title: commonGeneral.bodyTexts.back_button_label,
      onClick: () => commonGeneral.buttonsActions.onBackButtonClick?.(),
    }));

    return { mainBlockClass, buttonConfig };
  },
});
</script>
