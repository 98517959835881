import { $toastify } from "@/plugins/toastify";
import router from "@/routes/router";
import { transformMessage } from "@/utils/getErrorMessage";
import type { EditorError } from "@cna/api/types";
import { HttpStatusCode } from "axios";

export const isNotAuthenticated = (status?: number) =>
  status === HttpStatusCode.Forbidden || status == HttpStatusCode.Unauthorized;

export const catcher = (error: EditorError) => {
  console.error(error.response);

  if (isNotAuthenticated(error.status)) {
    console.log(error.response?.data.error);

    if (error.config?.url !== "/me/authenticated") {
      router.replace("/login");
    }
    return;
  }

  if (error.response?.status === HttpStatusCode.PayloadTooLarge) {
    $toastify.show({
      messageText: "The image is larger than 20MB",
      toastifyType: "danger",
    });
    return;
  }

  $toastify.show({
    messageText: transformMessage(error.response?.data.error),
    toastifyType: "danger",
  });
};
