<template>
  <div class="settings-section__row" :class="setDraggableRowClass">
    <div class="languages-section__language-info">
      <div class="languages-section__column">
        <span class="languages-section__language-title">
          {{ setLanguageTypeTitle }}
        </span>
      </div>
      <div class="languages-section__column settings-section__column--language">
        {{ allLanguageTags[item.lang_tag].isoname }}
        <template
          v-if="
            allLanguageTags[item.lang_tag].isoname !==
            allLanguageTags[item.lang_tag].longname
          "
        >
          ({{ allLanguageTags[item.lang_tag].longname }})
        </template>
      </div>
      <div class="languages-section__column settings-section__column--actions">
        <ButtonWithMenuList
          v-show="!isPrimary"
          :button-config="settingsMenuParams.buttonConfig"
        >
          <template #default="{ closeMenuList }">
            <MenuListWithButtons
              :config="settingsMenuParams.config"
              :on-click="closeMenuList"
            />
          </template>
        </ButtonWithMenuList>
      </div>
    </div>
    <span v-show="!isPrimary" class="icon-reorder" />
  </div>
</template>

<script lang="ts">
import ButtonWithMenuList from "@/components/buttonWithMenuList/ButtonWithMenuList.vue";
import MenuListWithButtons from "@/components/buttonWithMenuList/menuListsTypes/MenuListWithButtons.vue";
import { useLanguagesStore } from "@/stores/languages";
import { type Language } from "@cna/api/editor";
import { computed, defineComponent, ref, type PropType } from "vue";

export default defineComponent({
  name: "LanguagesSectionRow",
  components: {
    ButtonWithMenuList,
    MenuListWithButtons,
  },
  props: {
    item: {
      type: Object as PropType<Language>,
      required: true,
    },
  },
  emits: ["delete", "setPrimary"],
  setup(props, { emit }) {
    const languages = useLanguagesStore();
    const allLanguageTags = computed(() => languages.allLanguageTags);
    const setLanguageTypeTitle = computed(() => {
      return props.item.primary ? "Primary language" : "Language";
    });
    const showSettingsMenu = ref(false);
    const isPrimary = computed(() => props.item.primary);
    const setDraggableRowClass = computed(() => {
      return isPrimary.value ? "" : "draggable";
    });
    const isDeleteButtonDisabled = computed(() => props.item.primary);
    const openMenuList = () =>
      (showSettingsMenu.value = !showSettingsMenu.value);

    const settingsMenuParams = {
      buttonConfig: {
        iconClose: "icon-settings",
        size: "20px",
      },
      config: {
        items: [
          {
            title: "Set as primary",
            onClick: () => emit("setPrimary", props.item.lang_tag),
          },
          {
            title: "Delete",
            onClick: () => emit("delete", props.item.lang_tag),
          },
        ],
      },
    };

    return {
      allLanguageTags,
      setLanguageTypeTitle,
      openMenuList,
      isDeleteButtonDisabled,
      isPrimary,
      setDraggableRowClass,
      settingsMenuParams,
      showSettingsMenu,
    };
  },
});
</script>
