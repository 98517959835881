<template>
  <header>
    <div class="header flex-row-center">
      <div class="header__content flex-row-center-space-between">
        <LogoImage class="header__centered-logo" :config="logoConfig" />
        <LanguageDropdown
          :items="dropdownOptions"
          :on-change="onSelectLanguage"
          :current-option-key="currentLanguageKey"
        />
      </div>
    </div>
    <TrafficUsageMessage />
  </header>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LanguageDropdown from "../../components/dropdown/LanguageDropdown.vue";
import LogoImage from "../../components/logoImage/LogoImage.vue";
import { getInjectedHooks } from "../../hooks";
import TrafficUsageMessage from "./TrafficUsageMessage/TrafficUsageMessage.vue";
import useHeaderTemplatesHandler from "./useHeaderTemplatesHandler";

export default defineComponent({
  name: "WithCenteredLogo",
  components: {
    LogoImage,
    LanguageDropdown,
    TrafficUsageMessage,
  },
  setup() {
    const { logoConfig } = useHeaderTemplatesHandler();
    const languageDropdownProps = getInjectedHooks().useLanguageDropdown();

    return {
      logoConfig,
      ...languageDropdownProps,
    };
  },
});
</script>
