import { createClient } from "@cna/api/editor";
import type { AxiosInstance } from "axios";
import { catcher } from "./helpers";

const setupAxios = (a: AxiosInstance) => {
  a.interceptors.response.use(
    (res) => res,
    (err) => {
      catcher(err);
      return Promise.reject(err);
    }
  );
};

export const api = createClient(setupAxios);

//@ts-ignore
window.$$api = api;
